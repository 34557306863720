import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import icon57x57 from "../images/icons/apple-icon-57x57.png";
import icon60x60 from "../images/icons/apple-icon-60x60.png";
import icon72x72 from "../images/icons/apple-icon-72x72.png";
import icon76x76 from "../images/icons/apple-icon-76x76.png";
import icon114x114 from "../images/icons/apple-icon-114x114.png";
import icon120x120 from "../images/icons/apple-icon-120x120.png";
import icon144x144 from "../images/icons/apple-icon-144x144.png";
import icon152x152 from "../images/icons/apple-icon-152x152.png";
import icon180x180 from "../images/icons/apple-icon-180x180.png";
import icon192x192 from "../images/icons/android-icon-192x192.png";

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`${data.site.siteMetadata.title} | %s`}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="apple-touch-icon" sizes="57x57" href={icon57x57} />
            <link rel="apple-touch-icon" sizes="60x60" href={icon60x60} />
            <link rel="apple-touch-icon" sizes="72x72" href={icon72x72} />
            <link rel="apple-touch-icon" sizes="76x76" href={icon76x76} />
            <link rel="apple-touch-icon" sizes="114x114" href={icon114x114} />
            <link rel="apple-touch-icon" sizes="120x120" href={icon120x120} />
            <link rel="apple-touch-icon" sizes="144x144" href={icon144x144} />
            <link rel="apple-touch-icon" sizes="152x152" href={icon152x152} />
            <link rel="apple-touch-icon" sizes="180x180" href={icon180x180} />
            <link rel="icon" sizes="192x192" href={icon192x192} />
            <meta
              name="google-site-verification"
              content="Ar_q-BD6NAxXfDfaLhoGGjs8fgPBjspfnkzaFTvc8eI"
            />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
