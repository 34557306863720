import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Header from "./header";
import Navigation from "./navigation";
import Banner from "./banner";
import "./layout.scss";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      let displayBanner = false;
      if (typeof window !== "undefined") {
        displayBanner = window.location.pathname === "/" ? true : false;
      }
      return (
        <>
          {/* <Banner
            headline="Jeremy Scott Spring/Summer 2019 Runway Live"
            subHeadline="Live Tonight @ 9:00PM EST"
            display={displayBanner}
          /> */}
          <div className="content">{children}</div>
          <Navigation />
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
