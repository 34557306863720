import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import "./navigation.scss";

const Navigation = () => (
  <div className="navigation">
    <ul className="navigation__list clearfix">
      <li className="navigation__list-item" title="Contact" alt="Contact">
        <Link to="/contact/">
          <span>Contact</span>
        </Link>
      </li>
      <li
        className="navigation__list-item"
        title="Privacy Policy"
        alt="Privacy Policy"
      >
        <Link to="/privacy/">
          <span>Privacy</span>
        </Link>
      </li>
      <li
        className="navigation__list-item"
        title="Terms & Conditions"
        alt="Terms & Conditions"
      >
        <Link to="/terms/">
          <span>Terms</span>
        </Link>
      </li>
    </ul>
  </div>
);

export default Navigation;
